<template>
  <v-text-field
    label="选项"
    :value="`${value.length}项`"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="showDialog">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="480" persistent>
        <v-card>
          <v-card-title>
            <div>选项配置</div>
            <v-spacer></v-spacer>
            <v-btn icon @click="add"><v-icon>mdi-plus</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-list v-if="items.length">
            <v-list-item v-for="(item, i) in items" :key="i">
              <v-list-item-content>
                <v-text-field
                  label="名称"
                  v-model="item.text"
                  dense
                  hide-details
                ></v-text-field>
              </v-list-item-content>
              <v-list-item-content>
                <v-text-field
                  label="值"
                  v-model="item.value"
                  dense
                  hide-details
                ></v-text-field>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="remove(item)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-container v-else>
            <h3>没有选项</h3>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">确定</v-btn>
            <v-btn text @click="show = false">取消</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["controls", "value"],
  data() {
    return {
      items: [],
      show: false,
    };
  },
  methods: {
    showDialog() {
      this.items = JSON.parse(JSON.stringify(this.value));
      this.show = true;
    },
    add() {
      this.items.push({
        text: this.newName("选项"),
        value: "",
      });
    },
    remove(item) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i] == item) {
          this.items.splice(i, 1);
          break;
        }
      }
    },
    submit() {
      this.change(this.items);
      this.show = false;
    },
    change(value) {
      this.$emit("change", value);
    },
    newName(name) {
      let index = 1;
      let result = name;
      let exist = true;
      while (exist) {
        result = `${name}${index}`;
        exist = false;
        for (let i in this.items) {
          let item = this.items[i];
          if (item.text == result) {
            exist = true;
            break;
          }
        }
        if (exist) index++;
        else break;
      }
      return result;
    },
  },
};
</script>
